import axios from "axios";
import { LC_AUTH_TOKEN } from "./AppConst";
import { toast } from "react-toastify";

const __DEV__ = "development" === process.env.NODE_ENV;

// The server url
export const SERVER_URL = __DEV__
  ? "http://localhost:8000"
  : // "https://mon-permis.visibilitycam.com"
    "https://mon-permis.visibilitycam.com";

// Create axios client, pre-configured with baseURL
const APIKit = axios.create({
  baseURL: `${SERVER_URL}/admin-api/`,
  timeout: 100000,
});

//set the header
APIKit.defaults.headers.common["ADMIN"] = "Admin";

// Set JSON Web Token in Client to be included in all calls
export const setAccessToken = (token) =>
  (APIKit.defaults.headers.common["Authorization"] = `Bearer ${token}`);

APIKit.interceptors.request.use((req) => {
  __DEV__ && console.log("REQUEST", req);
  return req;
});

APIKit.interceptors.response.use(
  (res) => {
    __DEV__ && console.log("RESPONSE", res);
    return res;
  },
  (error) => {
    __DEV__ && console.log("ERROR", error);
    __DEV__ && console.log("RESPONSE-ERROR", error.response);
    if (error.response) {
      if (error.response.status === 401) {
        // log out user if we got a 401
        if (localStorage.getItem(LC_AUTH_TOKEN)) {
          localStorage.removeItem(LC_AUTH_TOKEN);
          window.location.reload();
        } else {
          throw error;
        }
      }

      if (error.response.status === 500) {
        console.log(error);
        toast.error("Une erreur c'est produite !", {
          position: toast.POSITION.TOP_RIGHT,
          pauseOnHover: true,
          autoClose: 5000,
          closeOnClick: true,
          hideProgressBar: true,
        });
        throw error;
      }

      if (error.response.status === 404) {
        //
        toast.error("l'element est introuvable !", {
          position: toast.POSITION.BOTTOM_CENTER,
          pauseOnHover: true,
          autoClose: 5000,
          closeOnClick: true,
          hideProgressBar: true,
        });
        throw error;
      }

      if (error.response.status === 422) {
        toast.warning(error.response.data.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          pauseOnHover: true,
          autoClose: 5000,
          closeOnClick: true,
          hideProgressBar: true,
        });
        throw error;
      }
    } else {
      throw error;
    }
  }
);

// All backend api url
export const API = {
  AUTH: {
    login: "auth/login",
    logout: "auth/logout",
  },
  AUTO: {
    get: "admin/auto_ecole",
    user: "admin/auto_ecole/users",
    userPaginate: "admin/auto_ecole/users/jquery-paginate",
    payment: "admin/auto_ecole/users/payments",
    statisque: "admin/auto_ecole/statisque",
  },
  CONDITION: {
    get: "condition_utilisations",
  },
  PULICITE: {
    get: "mobile/publicites",
  },
  USER: {
    get: "editUser",
  },
  MESSAGE: {
    get: "admin/help_message",
  },
  Maquette: {
    get: "admin/maquette",
  },
  Composant: {
    get: "admin/composant",
  },
  Panneaux: {
    get: "admin/panneau",
  },
  Messages: {
    get: "admin/messages",
  },
  Session: {
    get: "admin/session",
    changStatus: "admin/session/changStatus/{id}",
    archiver: "admin/session/archiver/{id}",
    candidat: "admin/session/{id}/candidats",
  },
  TypePanneaux: {
    get: "admin/tcat",
  },
  COMMUNE: {
    getVille: "admin/cities/cameroun",
    create: "admin/township",
    get: "admin/township",
  },
  TESTING: {
    questions: "admin/test_question",
    themes: "admin/test_theme",
  },
  OldSubject: {
    get: "admin/old_subject",
  },
  STATISTIQUE: {
    get: "admin/statistique",
  },
} as const;

export default APIKit;
